<template>
  <div :class="{ 'has-error': validation.hasError('ktpPhotoUuid') }" class="form--group">
    <div style="width: 100%;">
      <div class="left--col mb-2">
        <label for="dropzone-ktp">
          <span>{{ $t('profile.ktpPhoto') }}</span>
          <span class="required">*</span>
        </label>
      </div>
      <vue-dropzone
        ref="photoDropzone"
        id="dropzone-ktp"
        :options="dropzoneOptions"
        @vdropzone-file-added="added"
        @vdropzone-sending="sending"
        @vdropzone-success="success"
      />
      <!--          <button class="btn btn-primary&#45;&#45;outline ml-3" @click="onPickFileKtp">-->
      <!--            {{ $t('profile.changePhoto') }}-->
      <!--          </button>-->
      <!--          <img id="ktpPhotoPreview" ref="ktpPhotoPreview" />-->
      <!--          <input-->
      <!--            type="file"-->
      <!--            @change="onFileChangeKtp"-->
      <!--            ref="inputKtpPhoto"-->
      <!--            accept="image/*"-->
      <!--            style="display: none"-->
      <!--          />-->
      <span class="val-error mt-2">{{ validation.firstError('ktpPhotoUuid') }}</span>
    </div>
  </div>
</template>

<script>
import vue2Dropzone from 'nuxt-dropzone';
import 'nuxt-dropzone/dropzone.css';
import { Validator } from 'simple-vue-validator';

export default {
  name: 'ktp-photo',
  components: {
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      dropzoneOptions: {
        url: `${this.$store.state.global.apiUrl}/api/v2/file_content/upload_photo`,
        thumbnailWidth: 150,
        maxFilesize: 5,
        thumbnailMethod: 'contain',
        acceptedFiles: '.jpg,.png,.jpeg',
        // headers: {'X-CSRF-TOKEN': self.csrfToken},
        createImageThumbnails: true,
      },
    };
  },
  computed: {
    ktpPhotoUuid: {
      get() {
        return this.$store.state.checkout.billingInfo.ktpPhotoUuid;
      },
      set(val) {
        this.$store.commit('checkout/billingInfo/SET_KTP_PHOTO_UUID', val);
      },
    },
  },
  validators: {
    ktpPhotoUuid(value) {
      return Validator.value(value).required(this.$i18n.t('errors.identity_card_photo.required'));
    },
  },
  methods: {
    validate: function() {
      return this.$validate().then(
        function(success) {
          return !!success;
        }.bind(this),
      );
    },
    added() {
      const dropzone = this.$refs.photoDropzone;
      if (dropzone.dropzone.files.length > 1) {
        dropzone.removeFile(dropzone.dropzone.files[0]);
      }
    },
    // eslint-disable-next-line no-unused-vars
    sending(file, xhr, formData) {
      xhr.setRequestHeader('Authorization', `Bearer ${this.$store.state.global.token}`);
    },
    // eslint-disable-next-line no-unused-vars
    success(file, response) {
      console.log('RESPONSE SUCCESS upload', response);
      // let img = file.previewElement.querySelector('img');
      this.ktpPhotoUuid = response.data.photo_uuid;
      // this.$store.commit('v2/listingForm/sect1/media/ADD_PHOTO', {
      //   fileName: img.alt,
      //   src: img.src,
      //   uuid: response,
      //   isPrimary: false,
      // });
      // let dropzone = this.$refs.photoDropzone;
      // dropzone.removeFile(file);
    },
  },
};
</script>

<style scoped></style>
